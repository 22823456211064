<template>
  <ion-page v-if="jobs">
    <ion-header class="ion-no-border">
      <ion-toolbar mode="md" color="tertiary">
        <ion-buttons slot="end">
          <ion-button @click="openPopover($event)">
            <ion-icon slot="icon-only" :icon="ellipsisHorizontal"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title
          >Aupa <strong>{{ user }}</strong></ion-title
        >
      </ion-toolbar>
    </ion-header>
    <ion-content color="tertiary">
      <div class="custom-content">
        <div class="desc animate__animated animate__zoomIn titles">
          <strong>Datozen Lanak</strong>
        </div>

        <div style="margin-left:20px">
          <div
            v-if="countJobs >= 1"
            class="animate__animated animate__zoomInRight"
          >
            <strong>{{ countJobs }} lan</strong> dituzu egiteko
          </div>

          <div v-else class="animate__animated animate__zoomInRight">
            Ez duzu lanik egunotan egiteko...
          </div>
        </div>

        <div class="floated">
          <ion-card
            v-for="(item, index) in jobsHome"
            :key="index"
            mode="ios"
            class="custom-card animate__animated animate__fadeIn"
          >
            <ion-badge color="danger" v-if="item.difference <= 1"
              >{{ item.difference }} egun</ion-badge
            >
            <ion-badge
              color="warning"
              v-else-if="item.difference > 1 && item.difference < 5"
              >{{ item.difference }} egun</ion-badge
            >
            <ion-badge color="secondary" v-else
              >{{ item.difference }} egun</ion-badge
            >
            <ion-card-header>
              <ion-card-title>{{ item.job_term.name }}</ion-card-title>
              <ion-card-subtitle>{{ item.client.name }}</ion-card-subtitle>
            </ion-card-header>
          </ion-card>

          <div class="last"></div>
        </div>

        <div class="desc animate__animated animate__zoomIn titles">
          Aldaketak egin
        </div>

        <div class="floated floated--large">
          <ion-card
            mode="ios"
            class="custom-card animate__animated animate__fadeIn clients-card"
            color="primary"
            @click="addJobTerm"
          >
            <ion-card-header>
              <ion-icon :icon="notificationsOutline" size="large"></ion-icon>
              <ion-card-title>Lan mota berria</ion-card-title>
            </ion-card-header>
          </ion-card>

          <ion-card
            mode="ios"
            class="custom-card animate__animated animate__fadeIn clients-card"
            color="secondary"
            @click="addClient"
          >
            <ion-card-header>
              <ion-icon :icon="personAddOutline" size="large"></ion-icon>
              <ion-card-title>Bezero berria</ion-card-title>
            </ion-card-header>
          </ion-card>
        </div>

        <div
          class="desc animate__animated animate__zoomIn titles"
          style="margin-top: 0"
        >
          Ordainketak
        </div>

        <div class="floated floated--large">
          <ion-card
            mode="ios"
            class="custom-card animate__animated animate__fadeIn clients-card"
            color="dark"
          >
            <ion-card-header v-if="countPayments">
              <ion-badge color="danger"> {{ countPayments }} lan </ion-badge>
              <ion-icon :icon="cardOutline" size="large"></ion-icon>
              <ion-card-title>Ordaintzeko falta direnak</ion-card-title>
            </ion-card-header>

            <ion-card-header v-else>
              <ion-icon :icon="cardOutline" size="large"></ion-icon>
              <ion-card-title>Ez dago zorrik ordainketetan</ion-card-title>
            </ion-card-header>
          </ion-card>

          <div class="last"></div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonBadge,
  IonIcon,
  modalController,
  popoverController,
} from "@ionic/vue";

import {
  personAddOutline,
  addCircleOutline,
  cardOutline,
  arrowBackOutline,
  ellipsisHorizontal,
  notificationsOutline,
} from "ionicons/icons";

import Options from "@/components/Options.vue";
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ClientNew from "@/components/ClientNew.vue";
import JobTermNew from "@/components/forms/JobTermForm.vue";

export default {
  name: "Home",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonTitle,
    IonContent,
    IonBadge,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonIcon,
  },

  setup() {
    const jobs = ref(null);
    const store = useStore();
    const router = useRouter();
    const user = ref(store.state.user);

    const jobsHome = computed(() => {
      return getJobs.value.filter((res, index) => {
        if (index < 10) {
          return res;
        }
      });
    });

    const getUser = computed(() => store.state.user);
    const getJobs = computed(() => store.state.getJobs);
    const getPayments = computed(() => store.state.getPayments);

    const countJobs = ref(0);
    const countPayments = ref(0);

    watch(getUser, (value) => {
      user.value = value;
    });

    watch(getJobs, (value) => {
      jobs.value = value;
    });

    watch(getPayments, (value) => {
      getPaymentsLength(value);
    });

    watch(jobs, (value) => {
      countJobs.value = value.filter((find) => {
        return find.difference < 5;
      }).length;
    });

    onMounted(async () => {
      // get job terms
      await store.dispatch("_getJobTerms");
      // get jobs
      await store.dispatch("_jobsReload");
      // get Clients
      await store.dispatch("_getClients", 1);
      // get Payments
      await store.dispatch("_getPayments", 1);

      jobs.value = store.state.getJobs;
      if (store.state.getPayments) getPaymentsLength(store.state.getPayments);
    });

    const getPaymentsLength = (value) => {
      countPayments.value = value.filter((find) => {
        return find.paid != 1;
      }).length;
    };

    const openPopover = async (ev) => {
      const popover = await popoverController.create({
        component: Options,
        cssClass: "my-custom-class",
        event: ev,
        translucent: true,
      });
      return popover.present();
    };

    const addClient = async () => {
      let client = {};
      const modal = await modalController.create({
        component: ClientNew,
        componentProps: {
          client: client,
        },
      });
      return modal.present();
    };

    const addJobTerm = async () => {
      const modal = await modalController.create({
        component: JobTermNew,
        componentProps: {
          title: "Lan mota berria",
        },
      });
      return modal.present();
    };

    return {
      user,
      jobs,
      jobsHome,
      router,
      openPopover,
      addClient,
      addJobTerm,
      countJobs,
      countPayments,
      personAddOutline,
      addCircleOutline,
      cardOutline,
      arrowBackOutline,
      ellipsisHorizontal,
      notificationsOutline,
    };
  },
};
</script>

<style lang="scss" scoped>
.basemap {
  width: 100%;
  height: 400px;
}

.custom-content {
  padding: 0;

  .desc {
    padding: 0 20px;
  }
}

.custom-card {
  flex: 0 0 120px;
  box-sizing: border-box;
  animation-duration: 0.5s;
  margin: 15px 5px;
  &:nth-child(1) {
    animation-delay: 0.2s;
  }
  &:nth-child(2) {
    animation-delay: 0.4s;
  }
  &:nth-child(3) {
    animation-delay: 0.6s;
  }
  &:nth-child(4) {
    animation-delay: 0.8s;
  }
  &:nth-child(5) {
    animation-delay: 1s;
  }
  &:nth-child(6) {
    animation-delay: 1.2s;
  }
  &:nth-child(7) {
    animation-delay: 1.4s;
  }
  &:nth-child(8) {
    animation-delay: 1.6s;
  }

  ion-icon {
    opacity: 0.4;
  }
}

ion-badge {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 9;
}
ion-card-title {
  font-size: 15px;
  line-height: 15px;
  margin: 10px 0;
  font-weight: bolder;
}

ion-card-subtitle {
  font-size: 11px;
}

.titles {
  margin-top: 20px;
  font-weight: bold;
}
.floated {
  display: flex;
  width: 100%;
  overflow: auto;
  padding: 15px;

  &--large {
    .custom-card {
      flex: 0 0 135px;
    }
  }
  .last {
    clear: both;
    &:after {
      content: "...";
      visibility: hidden;
    }
  }
}

// clients
.clients-card {
  width: 45%;
  display: inline-block;
}
</style>
