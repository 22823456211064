<template>
  <ion-content class="ion-padding">
    <ion-list mode="md">
      <ion-label @click="logout" color="danger"
        ><strong>Saioa itxi</strong></ion-label
      >
    </ion-list>
  </ion-content>
</template>

<script>
import { IonContent, popoverController } from "@ionic/vue";
import { defineComponent } from "vue";
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;

export default defineComponent({
  name: "Popover",
  components: { IonContent },

  setup() {
    const store = useStore();
    const router = useRouter();
    const auth = ref(store.state.token);

    const logout = async () => {
      store.dispatch("logout");
      await Storage.remove({ key: "_userToken" });
      popoverController.dismiss();
      router.push("/");
    };
    return {
      auth,
      logout,
    };
  },
});
</script>
